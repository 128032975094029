<script>
export default {
  created() {
    // 跳转动态路由的第一个
    let firstRoute = this.$store.state.permissions.addRouters[0]
    this.$router.replace(firstRoute.children[0].path)
  },
  render: function(h) {
    return h() // avoid warning message
  }
}
</script>
